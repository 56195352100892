
html, body
  overflow: auto !important

.route-container > *
  width: 100%
.logo
  height: 55%
  img
    max-width: 100%
    max-height: 100%

#trellis-main
  padding: 0 !important
  height: calc(100% - 64px)

.with-banner
  margin-top: 128px !important

.no-banner
  margin-top: 64px !important

.v-main__wrap
  height: 100%

.main-wrapper
  display: flex
  flex-direction: column
  height: 100vh
  overflow: hidden

// .banner
//   flex-grow: 0
.v-application
  flex-grow: 1
  height: 100%
.scroll-container
  overflow: auto
  flex: 1 1 auto !important

.fade-in
  animation: fade-in .3s ease-in-out 0s 1
@keyframes fade-in
  0%
    opacity: 0
  100%
    opacity: 1

.cpu-optimized
  *:not(.stepper__wrapper)
    transition-property: none !important
    transition-duration: 0s !important
    animation: none !important
.page-footer
  background-color: #808080 !important

