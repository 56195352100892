
.dropzone
  padding: 70px
  background: rgba(40, 40, 40, .05)
  border: 2px dashed lightgrey
  border-radius: 10px
  transition: all .3s
  &:hover
    cursor: pointer
  &:hover, &.dragover
    background: rgba(40, 40, 40, .1)
  &.dense
    padding: 20px
  &.disabled
    background: rgba(40, 40, 40, .05)
    color: lightgrey
    border-color: rgba(40, 40, 40, .1)
  &.invalid
    cursor: no-drop
.hidden
  display: none
