
.loading-container
  display: block
  overflow: visible
  .loading-circle
    display: block
    border-radius: 50%
    height: 100%
    width: 100%
    border: 4px solid rgba(0,0,0,0)
    animation: rotate 1s linear infinite

// .cpu-optimized
//   .loading-container
//     .loading-circle

@keyframes rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
