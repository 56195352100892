.w-full
  width: 100%
.h-full
  height: 100%
.h-screen
  height: 100vh
.w-screen
  width: 100vw
.w-max-normal
  max-width: 1400px !important
.sticky
  position: sticky
.relative
  position: relative
.absolute
  position: absolute
.fixed
  position: fixed

.print-mode
  .v-progress-linear
    display: none
  .v-app-bar
    display: none
  .leaflet-control-container
    display: none
  .container, .content
    padding: 0
  .v-main
    min-height: 100vh

@media print
  html, body, .v-application, .main-wrapper
    height: auto !important
  html, body
    overflow: visible
  .main-wrapper
    overflow: visible !important
    min-height: 100% !important
    nav
      display: none
    header.v-app-bar
      display: none
    #trellis-main
      margin: 0 !important
      min-height: 100vh !important
  //   height: auto !important
  // .v-application--wrap
  //   display: flex !important
  // .v-main
  //   display: flex !important

.pointer
  cursor: pointer

.uppercase
  text-transform: uppercase
.lowercase
  text-transform: lowercase


// Documentation styles
a.anchor
  color: black
  text-decoration: none
  &:hover
    text-decoration: underline

.theme--dark
  a.anchor
    color: white


.bottom
  bottom: 0
.fixed
  position: fixed
    