
.scroll-container
  height: 100%
  max-height: 100%
  width: 100%
  overflow: hidden
  position: relative
  .header
    width: 100%
    z-index: 1
    position: relative
    transition: all .3s
  .scrollable
    z-index: 0
    position: relative
    overflow-y: auto
